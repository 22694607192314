<template>
  <header class="suporte-header">
    <div class="header-content">
      <div class="line1">
        <div class="logo" v-if="exclusive"><img src="../../../assets/images/sl-exclusive.png"/></div>
        <div class="logo" v-else><img src="../../../assets/images/sl-premium.png"/></div>
        <div class="login-bar">
          <suporte-profile-menu />
          <div class="bar-border">
            <router-link tag="a" :to="{name: 'logout'}">SAIR</router-link>
          </div>
        </div>
      </div>
      <div class="line2 line-menu">
        <ul class="suporte-page-menu">
          <suporte-menu-item
              v-for="m in menu"
              :key="m.name"
              :name="m.name"
              :icon="m.icon"
              :label="m.label"
              :route="m.route"
              :disabled="m.disabled"
          />
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import SuporteProfileMenu from "components/layout/suporte/ProfileMenu"
import SuporteMenuItem from "components/layout/suporte/MenuItem"
export default {
  name: "SuporteHeader",
  provide: function () {
    return {
      header: this
    }
  },
  data () {
    return {
      menu: [
        {name: 'suporte', label: 'Suporte', icon: 'fal fa-ticket', route: 'suporte.dashboard'},
        {name: 'doc', label: 'Documentação', icon: 'fal fa-book', route: 'ajuda.home'},
        {name: 'changelog', label: 'Changelog', icon: 'fal fa-code-branch', disabled: true},
        {name: 'api', label: 'API', icon: 'fal fa-code', disabled: true},
        {name: 'contract', label: 'Contrato SL', icon: 'fal fa-file-contract', disabled: true},
        {name: 'financeiro', label: 'Financeiro', icon: 'fal fa-money-check', disabled: true},
      ],
      menuActive: 'suporte'
    }
  },
  computed: {
    exclusive () {
      const currentURL = window.location.href
      if (currentURL.startsWith('http://') || currentURL.startsWith('https://')) {
        const urlWithoutProtocol = currentURL.slice(currentURL.indexOf('//') + 2)
        if (urlWithoutProtocol.startsWith('exclusive')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  components: {SuporteMenuItem, SuporteProfileMenu}
}
</script>
