<script>
import Menu from '../menu'
import DefaultMixin from '../mixin'
import Profile from "components/suporte/components/include/Profile"
import MenuDash from "components/suporte/components/include/MenuDash"
import MenuProjetos from "components/suporte/components/include/MenuProjetos"
// import MenuTarefas from "components/suporte/components/include/MenuTarefas"
// import MenuClientes from "components/suporte/components/include/MenuClientes"
import SuporteHeader from "components/layout/suporte/Header"

export default {
  name: 'Suporte',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    SuporteHeader,
    // MenuClientes,
    // MenuTarefas,
    MenuProjetos,
    MenuDash,
    Profile
  },
  data () {
    return {
      menuActive: 'dashboard'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.adjustRoute()
    })
  },
  computed: {
    menu () {
      return Menu
    },
    css () {
      const css = []
      if (this.$route.name === 'suporte.dashboard') css.push('is-dashboard')
      return css
    }
  },
  watch: {
    '$route.name' (v) {
     this.adjustRoute(v)
    }
  },
  methods: {
    adjustRoute (v) {
      if (v && v === 'suporte.dashboard') {
        this.menuActive = 'dashboard'
      } else {
        this.menuActive = this.$route.name
      }
    }
  },
  meta: {
    title: 'Suporte',
    name: 'Suporte'
  }
}
</script>

<template>
  <div class="suporte-app">
    <suporte-header ref="header" />
    <div class="suporte-page" :class="css">
      <div class="suporte-page-container">
        <div class="sleft" id="suporte-page-left">
          <profile />
          <div class="component-opts"></div>
          <div id="suporte-page-menu" ref="menu">
            <menu-dash class="level1" :class="{show: $route.name === 'suporte.dashboard'}" />
            <menu-projetos class="level2" :class="{show: $route.name === 'suporte.projetos'}" />
  <!--          <menu-tarefas class="level2" :class="{show: $route.name === 'suporte.tarefas'}" />-->
<!--            <menu-clientes class="level2" :class="{show: $route.name === 'suporte.clientes'}" />-->
          </div>
          <div class="bar-resize"></div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
