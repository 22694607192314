<template>
  <li>
    <router-link tag="a" :to="routeData" :class="{active: header.menuActive === name, disabled: disabled}">
      <div class="micon"><i :class="icon"></i></div>
      <span class="lbl">{{ label }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "SuporteMenuItem",
  inject: ['header'],
  props: ['icon', 'name', 'label', 'route', 'disabled'],
  computed: {
    routeData () {
      return this.route ? {name: this.route} : ''
    }
  }
}
</script>
