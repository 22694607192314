<template>
  <div class="suporte-profile-menu">
    <div class="cprofile">
      <div class="person-avatar-name">
        <div class="person-avatar avatar inline">
<!--          <img v-if="$uloc.auth.session.user.photo" width="25" class="" :src="$uloc.auth.session.user.photo"/>
          <div v-else class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>-->
          <div class="img-fake">{{ firstLettersName($uloc.auth.session.user.name) }}</div>
        </div>
        <div class="name">Olá, <span class="uppercase">{{firstname($uloc.auth.session.user.name)}}!</span></div>
      </div>
    </div>
    <div class="config">
      <i class="menu-icon fa fa-cog"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuporteProfileMenu"
}
</script>
